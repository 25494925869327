<template>
  <div
    class="min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        src="../assets/logo.png"
        alt="AI Reporting Assistant Logo"
      />
      <h2 class="mt-6 text-center text-5xl font-extrabold text-white">
        BOSS.AI
      </h2>

      <h4
        class="mt-2 text-center text-base font-extrabold text-white text-opacity-70"
      >
        Giriş Yapın
      </h4>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="handleSubmit">
          <div>
            <div class="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                placeholder="E-posta Adresiniz"
                required
                v-model="email"
                :disabled="loading"
                class="appearance-none block w-full px-3 py-2 border-b border-white border-opacity-35 shadow-sm placeholder-white focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm bg-black text-white"
              />
            </div>
          </div>

          <div>
            <div class="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                :disabled="loading"
                placeholder="Parolanız"
                v-model="password"
                class="appearance-none block w-full px-3 py-2 border-b border-white border-opacity-35 shadow-sm placeholder-white focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm bg-black text-white"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              :disabled="loading"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {{ loading ? "Bilgileriniz Kontrol Ediliyor" : "Giriş yap" }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <label
      class="fixed bottom-3 left-0 w-full block text-center text-white text-opacity-70 text-sm"
      >Powered by siriusaitech.com</label
    >
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const store = useStore();
    const router=useRouter();

    let loading = ref(false);

    const handleSubmit = async () => {
      try {
        loading.value = true;
        // Burada giriş işlemini gerçekleştirebilirsiniz
        const result = await axios.post(
          store.state.baseUrl + "/users/sign/in",
          {
            email: email.value,
            password: password.value,
          }
        );

        store.commit('setUserData',result.data.data)

        console.log("girdi 1")

        router.push('/main');

        loading.value = false;
      } catch (error) {
        loading.value = false;

        console.log(error);
        alert(error?.response?.data?.message || error);
      }
    };

    return {
      email,
      password,
      handleSubmit,
      loading
    };
  },
};
</script>
