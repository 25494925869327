<template>
  <div class="w-full flex items-center justify-between relative">
   
    <profileInput />
    <dataTable />
    <assistantSection />
    <dataSection />
  </div>

  <!-- EXCEL IMPORT  -->
</template>

<script>

import { defineComponent, defineAsyncComponent } from "vue";
export default defineComponent({
  components: {
    assistantSection: defineAsyncComponent(() => 
      import('./assistant_section.vue')
    ),
    dataSection:defineAsyncComponent(() => 
      import('./data_section.vue')
    ),
    profileInput:defineAsyncComponent(() => 
      import('../../components/profile_panel.vue')
    ),
    dataTable:defineAsyncComponent(() => 
      import('../../components/data_panel.vue')
    )
  }
})
</script>

