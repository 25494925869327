import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import signInPage from "../views/sign_page.vue";
import mainPage from "../views/main/index_page.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "signInPage",
    component: signInPage,
  },
  {
    path: "/main",
    name: "mainInPage",
    component: mainPage,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
