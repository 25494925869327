import axios from "axios";
import { ActionContext, createStore, Store as VuexStore, useStore as vuexUseStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// Message interface
interface Message {
  id: number;
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

// UserData interface
interface UserData {
  fullname: string;
  token: string;
}

// RootState interface
interface RootState {
  baseUrl: string;
  userData: UserData;
  messages: Message[];
  service_token:string;
}

// Store type
export type Store = VuexStore<RootState>



// API function
async function callChatAPI(context: ActionContext<RootState, RootState>, message: string): Promise<string> {
  try {
    const response = await axios.get(`${context.state.baseUrl}/web?question=`+message, {
      headers: {
        'Authorization': `Bearer ${context.state.userData.token}`
      }
    });
    return response.data.data; // Assuming the API returns a 'message' field
  } catch (error) {
    console.error('Error in API request:', error);
    throw error;
  }
}

const store = createStore({
  state: {
    // baseUrl: 'http://localhost',
    baseUrl: 'http://35.208.134.237:8010',
    userData: {
      fullname: '',
      token: ''
    },
    service_token:"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",
    messages: []
  } as RootState,

  getters: {
    getMessages: (state: RootState): Message[] => state.messages,
    getUserData: (state: RootState): UserData => state.userData,
    getBaseUrl: (state: RootState): string => state.baseUrl
  },

  mutations: {
    setUserData(state: RootState, val: UserData) {
      state.userData = val;
    },
    addMessage(state: RootState, val: Message) {
      state.messages.push(val);
    },
    clearMessage(state: RootState) {
      state.messages=[];
    }
  },

  actions: {
    async addMessageAction(context, messageData: { role: 'user' | 'assistant', content: string }) {
      // Add user message
      const userMessage: Message = {
        id: Date.now(),
        role: 'user',
        content: messageData.content,
        timestamp: new Date()
      };
      context.commit('addMessage', userMessage);

      try {
        // Call API
        const apiResponse = await callChatAPI(context, messageData.content);

        // Add assistant message
        const assistantMessage: Message = {
          id: Date.now(),
          role: 'assistant',
          content: apiResponse,
          timestamp: new Date()
        };
        context.commit('addMessage', assistantMessage);

      } catch (error) {
        console.error('Error in API request:', error);
        // Optionally, you can add an error message to the chat
        const errorMessage: Message = {
          id: Date.now(),
          role: 'assistant',
          content: 'Sorry, I encountered an error while processing your request.',
          timestamp: new Date()
        };
        context.commit('addMessage', errorMessage);
      }
    },
    clearMessage(context){
      context.commit('clearMessage');
    }
  },

  modules: {},
  plugins: [createPersistedState({
    storage: window.localStorage,
    paths: ['userData', 'messages']  // Sadece bu state'leri persist et
  })]
});

export default store;

// Define and export custom typed useStore function
export function useStore(): Store {
  return vuexUseStore() as Store;
}